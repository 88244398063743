<!-- 汇总式表格图表：componentType => 'report_summary' -->
<template>
  <el-table
    :data="tableDataMerge"
    border
    style="width: 100%"
    :span-method="objectSpanMethod"
    :cell-style="{ verticalAlign: 'baseline' }"
    height="100%"
    :empty-text="$t('vue_label_SMS_nodata')"
    @cell-click.self="$emit('clickTable')"
  >
    <!-- 统计字段的统计列 -->
    <el-table-column
      v-for="(item, index) in colData"
      :key="`${item.id}-${index}`"
      :prop="getName(item)"
      :min-width="judgeNumber(item) ? item.width : '120'"
      :show-overflow-tooltip="true"
      resizable
    >
      <template #header>
        <div class="header_div">
          <div>{{ item.labelName }}</div>
          <div v-if="item.summaryWay">
            <span v-if="item.summaryWay === 'sum'">{{
              $t("label.summation")
            }}</span>
            <span v-else-if="item.summaryWay === 'avg'">{{
              $t("label.average")
            }}</span>
            <span v-else-if="item.summaryWay === 'max'">{{
              $t("label.maximum")
            }}</span>
            <span v-else-if="item.summaryWay === 'min'">{{
              $t("label.minimun")
            }}</span>
          </div>
        </div>
      </template>
      <template slot-scope="scope">
        <div :class="{ active: isJump(scope.row, item) }">
          <!-- 根据isJump方法判断是否可跳转，若是可以跳转则添加选项卡打开功能 -->
          <a
            v-if="isJump(scope.row, item)"
            @click.prevent="cardViewjump(scope.row, item)"
            :href="`/#/commonObjects/detail/${returnHref(
              scope.row,
              item
            )}/DETAIL`"
            target="_blank"
          >
            {{ scope.row[getName(item)] }}</a
          >
          <!-- 不可跳转 -->
          <!-- 不是数值类型无千分位 -->
          <span v-if="!judgeNumber(item) && !isJump(scope.row, item)">
            {{ scope.row[getName(item)] }}
          </span>
          <!-- 数值类型字段，统计列（包含记录数）需要右对齐 -->
          <div v-if="judgeNumber(item) && !isJump(scope.row, item)">
            <div
              :class="{ number_style: item.summaryWay || judgeNumber(item) }"
            >
              {{ scope.row[getName(item)] | ThousandSeparator }}
            </div>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mergeTableRow } from "@/config/table/mergeTableRow";
import { statisticsTypes } from "@/config/reportDataType/unitData";

export default {
  props: {
    tableData: {
      // 表格数据
      type: Array,
      default: () => [],
    },
    objectSpanMethod: {
      // 单元格合并
      type: Function,
      default: () => {},
    },
    colData: {
      // 列数据
      type: Array,
      default: () => [],
    },
    mapGroupOneField: {
      // 一级分组
      type: Object,
      default: () => {},
    },
    mapGroupTwoField: {
      // 二级分组
      type: Object,
      default: () => {},
    },
    isJump: {
      // 判断是否可跳转
      type: Function,
      default: () => {},
    },
    judgeNumber: {
      // 判断是否添加千分号
      type: Function,
      default: () => {},
    },
    cardViewjump: {
      // 点击跳转详情页
      type: Function,
      default: () => {},
    },
    returnHref: {
      // 获取可以跳转的数据id，在地址中进行拼接，来添加选项卡打开功能
      type: Function,
      default: () => {},
    },
    getName: {
      // 显示是否需要加ccname，获取对应的name
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      statisticsTypes: statisticsTypes(), // 统计类型
    };
  },
  computed: {
    // 对表格数据进行行分组的合并
    tableDataMerge() {
      let mergeRowArr = [];
      if (this.mapGroupOneField) {
        mergeRowArr.push(this.mapGroupOneField.schemefield_name_alias);
      }
      if (this.mapGroupTwoField) {
        mergeRowArr.push(this.mapGroupTwoField.schemefield_name_alias);
      }
      return mergeTableRow(this.tableData, mergeRowArr);
    },
  },
  watch: {
    colData: {
      // 拼接列表名称和每列数据，计算出每个数据的宽度，选出最大值
      // 对普通字段的宽度上默认增加40px
      // 对汇总的字段宽度上增加80px,适应表格名称
      handler: function (val) {
        let that = this;
        let arr = [];
        if (val && this.tableDataMerge) {
          val.map((item1) => {
            arr = [];
            arr.push(item1.labelName);
            this.tableDataMerge.map((item2) => {
              if (item2[item1.schemefield_name_alias]) {
                arr.push(item2[item1.schemefield_name_alias]);
              }
              if (item1.schemefield_name_alias.indexOf("$")) {
                item1.width = that.getMaxLength(arr) + 80;
              } else {
                item1.width = that.getMaxLength(arr) + 40;
              }
            });
          });
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 选择每列中长度的最大值
    getMaxLength(arr) {
      return arr.reduce((acc, item) => {
        if (item) {
          let calcLen = this.getTextWidth(item);
          if (acc < calcLen) {
            acc = calcLen;
          }
        }
        return acc;
      }, 0);
    },
    // 计算每列中数据的长度
    getTextWidth(str) {
      let width = 0;
      let html = document.createElement("span");
      html.innerText = str;
      html.className = "getTextWidth";
      document.querySelector("body").appendChild(html);
      width = document.querySelector(".getTextWidth").offsetWidth;
      document.querySelector(".getTextWidth").remove();
      return width;
    },
  },
};
</script>

<style lang='scss' scoped>
.header_div {
  display: flex;
  flex-wrap: wrap;
}
</style>