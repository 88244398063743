<!--
  图表组件DashboardCard：表格图表：报表数据源、列表视图数据源
-->
<template>
  <div class="content">
    <list-type-form-chart
      v-if="dashboardtype === 'tableDetail'"
      :tableData="tableData"
      :colData="colData"
      :isJump="isJump"
      :judgeNumber="judgeNumber"
      :cardViewjump="cardViewjump"
      :returnHref="returnHref"
      :getName="getName"
      @clickTable="clickTable"
    />
    <summaryTypeFormChart
      v-else-if="dashboardtype === 'report_summary'"
      :tableData="tableData"
      :colData="colData"
      :judgeNumber="judgeNumber"
      :mapGroupOneField="mapGroupOneField"
      :mapGroupTwoField="mapGroupTwoField"
      :objectSpanMethod="objectSpanMethod"
      :isJump="isJump"
      :cardViewjump="cardViewjump"
      :returnHref="returnHref"
      :getName="getName"
      @clickTable="clickTable"
    />
  </div>
</template>

<script>
import listTypeFormChart from "./listTypeFormChart"; // 列表式表格图表
import summaryTypeFormChart from "./summaryTypeFormChart"; // 汇总式表格图表
import { owneridIsJump } from "@/config/commonMehods/everyoneJump.js"; // 判断所有人是否跳转权限方法

export default {
  components: {
    listTypeFormChart,
    summaryTypeFormChart,
  },
  props: {
    dashboardtype: {
      // 表格图表类型：tableDetail、report
      type: String,
      default: "tableDetail",
    },
    tableData: {
      // 表格数据
      type: Array,
      default: () => [],
    },
    colData: {
      // 列数据
      type: Array,
      default: () => [],
    },
    sourceType: {
      // 图表使用的数据源
      type: String,
      default: "report",
    },
    mapGroupOneField: {
      // 一级分组
      type: Object,
      default: () => {},
    },
    mapGroupTwoField: {
      // 二级分组
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // cellClass({ row, column, rowIndex, columnIndex }) {
    //   // 1.合计行
    //   if (row.t0name === "合计") {
    //     return "background-color: #EFF7FC;color:#606266";
    //   } else {
    //     return "background-color: #ffffff;color:#606266";
    //   }
    // },
    // 合并行列
    objectSpanMethod({ row, column}) {
      const span = column["property"] + "-span";
      if (row[span]) {
        return row[span];
      }
    },
    // 点击表格
    clickTable() {
      this.$emit("clickTable");
    },
    /*  判断是否可跳转，后端已做过判断，isAddLink为true、且有要跳转的id才可跳转，
        所有人比较特殊，若是所有人字段还需判断是否满足规则。
    */
    isJump(row, item) {
      if (item.isAddLink && this.returnHref(row, item)) {
        if (
          item.schemefieldName !== "ownerid" ||
          (item.schemefieldName === "ownerid" &&
            ((this.sourceType === "listView" &&
              owneridIsJump(row[item.schemefieldName])) ||
              (this.sourceType === "report" &&
                owneridIsJump(row[item.schemefield_name_alias]))))
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    // 根据字段类型判断是否需要添加千分位
    judgeNumber(item) {
      let schemefieldType = item.schemefieldType || item.fieldtype;
      let expressionType = item.expressionType;

      if (
        schemefieldType === "C" ||
        schemefieldType === "P" ||
        schemefieldType === "SCORE" ||
        schemefieldType === "c" ||
        schemefieldType === "N" ||
        (schemefieldType === "Z" &&
          (expressionType === "N" ||
            expressionType === "P" ||
            expressionType === "c"))
      ) {
        return true;
      }
      return false;
    },
    //点击跳转详情页
    cardViewjump(row, item) {
      // 先判断是否可跳转
      if (this.isJump(row, item)) {
        let option = this.returnHref(row, item);
        this.$router.push({
          path: `/commonObjects/detail/${option}/DETAIL`,
        });
      }
    },
    // 获取可以跳转的数据id，在地址中进行拼接，来添加选项卡打开功能
    returnHref(row, item) {
      if (this.sourceType === "report") {
        // 报表数据源
        return row[item.linkId];
      } else {
        // 列表视图数据源
        if (item.schemefieldName === "name") {
          return row.id;
        } else {
          return row[item.schemefieldName];
        }
      }
    },
    // 显示是否需要加ccname，获取对应的name
    getName(item) {
      if (this.sourceType === "report") {
        // 报表数据源，后端已经做过判断，返回了加上ccname之后的一个key值。
        return item.schemefield_name_alias;
      } else {
        // 列表视图数据源，需要区分是否带有分组，table行数据的prop取值不同
        if (this.dashboardtype === "report_summary") {
          return item.schemefield_name_alias;
        }
        // 需要根据字段类型判断是否需要加ccname
        if (
          item.schemefieldType === "Y" ||
          item.schemefieldType === "M" ||
          item.schemefieldType === "R" ||
          item.schemefieldType === "MR"
        ) {
          // 查找、主祥字段、记录类型字段、查找多选字段
          return item.schemefieldName + "ccname";
        } else if (
          item.schemefieldType === "S" &&
          (item.schemefieldName === "whoid" ||
            item.schemefieldName === "relateid")
        ) {
          // 相关项、名称字段
          return item.schemefieldName + "ccname";
        } else {
          return item.schemefieldName;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  height: 100%;
  padding: 5px 0;
  ::v-deep .el-table {
    border: 1px solid #dddbda;
  }
  ::v-deep .el-table .el-table__body {
    cursor: pointer;
  }
  ::v-deep .el-table td,
  .el-table th {
    padding: 7px 0;
  }
  ::v-deep .el-table .cell {
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  ::v-deep .el-table thead th,
  .el-table thead tr {
    border: 1px solid #7f8de1;
    background: #7f8de1;
    color: #fff;
    padding: 7px 0;
  }
  .active {
    color: #006dcc !important;
    cursor: pointer;
  }
}
::v-deep .number_style {
  text-align: right;
  justify-content: flex-end;
}
</style>