<!-- 列表式表格图表：没有分组 -->
<template>
  <el-table
    :data="tableData"
    style="width: 100%"
    height="100%"
    border
    :empty-text="$t('vue_label_SMS_nodata')"
    @cell-click.self="$emit('clickTable')"
  >
    <el-table-column
      v-for="(item, index) in colData"
      :key="index"
      :label="item.label_name || item.labelname"
      :min-width="judgeNumber(item) ? item.width : '120'"
      :show-overflow-tooltip="true"
      resizable
    >
      <template slot-scope="scope">
        <div :class="{ active: isJump(scope.row, item) }">
          <!-- 根据isJump方法判断是否可跳转，若是可以跳转则添加选项卡打开功能 -->
          <a
            v-if="isJump(scope.row, item)"
            @click.prevent="cardViewjump(scope.row, item)"
            :href="`/#/commonObjects/detail/${returnHref(
              scope.row,
              item
            )}/DETAIL`"
            target="_blank"
          >
            {{ scope.row[getName(item)] }}</a
          >
          <!-- 不可跳转 | ThousandSeparator -->
          <!-- 不是数值类型无千分位 -->
          <span v-if="!judgeNumber(item) && !isJump(scope.row, item)">
            {{ scope.row[getName(item)] }}
          </span>
          <div v-if="judgeNumber(item) && !isJump(scope.row, item)">
            <!-- 数值类型字段，需要右对齐 -->
            <div class="number_style">
              {{ scope.row[getName(item)] | ThousandSeparator }}
            </div>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    tableData: {
      // 表格数据
      type: Array,
      default: () => [],
    },
    colData: {
      // 列数据
      type: Array,
      default: () => [],
    },
    isJump: {
      // 判断是否可跳转
      type: Function,
      default: () => {},
    },
    judgeNumber: {
      // 判断是否添加千分号
      type: Function,
      default: () => {},
    },
    cardViewjump: {
      // 点击跳转详情页
      type: Function,
      default: () => {},
    },
    returnHref: {
      // 获取可以跳转的数据id，在地址中进行拼接，来添加选项卡打开功能
      type: Function,
      default: () => {},
    },
    getName: {
      // 显示是否需要加ccname，获取对应的name
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  watch: {
    colData: {
      // 拼接列表名称和每列数据，计算出每个数据的宽度，选出最大值
      // 对普通字段的宽度上默认增加40px
      // 对汇总的字段宽度上增加80px,适应表格名称
      handler: function (val) {
        let that = this;
        let arr = [];
        if (val && this.tableDataMerge) {
          val.map((item1) => {
            arr = [];
            arr.push(item1.labelName);
            this.tableDataMerge.map((item2) => {
              if (item2[item1.schemefield_name_alias]) {
                arr.push(item2[item1.schemefield_name_alias]);
              }
              if (item1.schemefield_name_alias.indexOf("$")) {
                item1.width = that.getMaxLength(arr) + 80;
              } else {
                item1.width = that.getMaxLength(arr) + 40;
              }
            });
          });
        }
      },
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 选择每列中长度的最大值
    getMaxLength(arr) {
      return arr.reduce((acc, item) => {
        if (item) {
          let calcLen = this.getTextWidth(item);
          if (acc < calcLen) {
            acc = calcLen;
          }
        }
        return acc;
      }, 0);
    },
    // 计算每列中数据的长度
    getTextWidth(str) {
      let width = 0;
      let html = document.createElement("span");
      html.innerText = str;
      html.className = "getTextWidth";
      document.querySelector("body").appendChild(html);
      width = document.querySelector(".getTextWidth").offsetWidth;
      document.querySelector(".getTextWidth").remove();
      return width;
    },
  },
};
</script>

<style lang='scss' scoped>
</style>